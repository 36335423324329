import { isChineseDomain } from "@/utils";
const isChineseDomainFlag = isChineseDomain();

export default {
  /**
   * 素材
   */
  StickerLabel: 'Sticker',
  Use: 'USE',
  Home: 'Home',
  Design: 'Design',
  Train: 'Train',
  SignIn: 'Log in',
  SignUp: 'Get started for free',
  Contact: 'Contact',
  ComingSoon: 'Coming soon~',
  Email: 'Email',
  DigitalTwin: 'DigitalTwin',
  Resample: 'Resample',
  Rembg: 'Rembg',
  ToolBox: 'ToolBox',
  AIPaint: 'AIPaint',
  Profile: 'Profile',
  MyPage: 'HomePage',
  Developer: 'Developer',
  AIWorkflow: 'AI Workflow',
  AIApp: 'AI App',
  AppBuilder: 'App Builder',
  Explore: 'Explore',
  AIAppsBuilder: 'AIAppsBuilder',
  Studio: 'Studio',
  Member: 'Member',
  MyWorkflow: 'My Workflow',
  EditorPick: 'Editor\'s Pick',
  UploadWorkflow: 'Build AI App',
  UserCenter: 'User Center',
  Logout: 'Sign Out',
  AIBoostArt: 'AI Boosting Artistry',
  BornForDesigner: 'Born for the Next-Gen Designers.',
  NoWorkflow: 'No Workflow Yet',
  TXT2IMG: 'TXT2IMG',
  openApp: 'Open App',
  editApp: 'Edit App',
  FreeTrial: 'Free Trial',
  Discount: 'Discount',
  generate: 'Generate',
  generating: 'Generating...',
  generateFailed: 'Generate Failed',
  Documentations: 'Documentations',
  Recharge: 'Recharge',
  workflow: {
    app: {
      left: 'Left',
      MCoin: 'MCoin',
      playground: 'Playground',
      api: 'Open API',
      comingSoon: 'Coming Soon',
      cost: 'Cost',
    }
  },
  home: {
    AIWorkflow: 'AI Workflow',
    AIWorkflowDesc: 'AI Workflow is a tool that helps you to create AI applications without coding.',
    AIPainting: 'AI Painting',
    AIPaintingDesc: 'AI Painting WebUI, save painting parameters with one click.',
    malette: 'Malette',
    product: 'Product',
    getStarted: 'Get Started',
    connect: 'Connect',
    aiPainting: 'AI Painting',
    aiWorkflow: 'AI Workflow',
    styleFusion: 'Style Fusion',
    stickers: 'Stickers',
    tryOn: 'Try On',
    imageTranslate: 'Manga Translation',
    winkPortraits: 'Wink Portraits',
    muscleKing: 'Muscle King',
    socialCard: 'Social Card Generator',
    CloudComfyUI: 'Cloud ComfyUI',
    CloudComfyUIDesc: 'Cloud ComfyUI, no need to install models or nodes, ready to use out of the box, enjoy the ultra-fast experience.',
    pricing: 'Pricing',
    Tutorials: 'Tutorials',
    FAQ: 'FAQ',
    comingSoon: 'Coming Soon',
    sloganTitle: 'Deploy AI App Service',
    sloganCore: 'One Workflow',
    sloganTitleEnd: ' Is All You Need.',
    sloganSubtitle: 'From Cloud ComfyUI to Complete AI Applications, One-Stop Solution',
    appName: isChineseDomainFlag ? 'Malette.Art' : 'Malette.AI',
    textSticker: 'Text Sticker',
    aiHair: "AI Hair Color",
    stickerMaker: "Sticker Maker",
    textStickerDesc: 'Generate stickers with one click.',
    heroTitle: "Run AI Workflow<br></br> with an <bold>API.</bold>",
    heroSubtitle: "Malette.AI is a platform that allows you to run AI workflows with an API.",
    watchDoc: 'View Documents',
    tutorials: 'Tutorials',
    faq: 'FAQ',
    features: {
      cloudComfyui: {
        title: 'Cloud ComfyUI',
        desc: 'No need to install models or nodes, ready to use out of the box, enjoy the ultra-fast experience.'
      },
      aiApp: {
        title: 'AI App',
        desc: 'Turn workflows into AI apps and APIs with one click.'
      },
      community: {
        title: 'Community',
        desc: 'Explore and use high-quality AI apps created by the community'
      }
    },
    welcomeText: 'Welcome to Malette',
    coreFeatures: 'Core Capabilities',
    coreFeaturesDesc: 'One-stop solution from cloud workflow orchestration to API deployment',
    easyIntegration: 'Easy Integration',
    easyIntegrationDesc: 'Seamlessly integrate with any application via RESTful API',
    cloudInfra: 'Cloud Infrastructure',
    cloudInfraDesc: 'High-performance GPU clusters, no hardware configuration needed',
    quickDeploy: 'Quick Deployment',
    quickDeployDesc: 'Convert workflows to API endpoints with one click',
    popularWorkflows: 'Popular AI Apps',
    popularWorkflowsDesc: 'Explore the most popular AI applications in our community',
    txt2img: 'Text to Image',
    txt2imgDesc: 'Generate high-quality images from natural language descriptions',
    styleTransfer: 'Style Transfer',
    styleTransferDesc: 'Transform any image into specific artistic styles',
    imageEdit: 'Smart Image Editor',
    imageEditDesc: 'Powerful AI-driven image editing tools',
    codeSwitch: {
      javascript: 'JavaScript SDK',
      python: 'Python SDK',
      curl: 'cURL',
      wip: 'Work in Progress',
      wipDesc: 'JavaScript SDK is under development',
      soon: 'Coming Soon',
      comingSoon: 'Coming Soon',
      stayTuned: 'We are developing a more convenient SDK integration solution'
    },
  },
  loginForm: {
    login: 'Log in',
    email: 'Email',
    password: 'Password',
    remember: 'Remember me',
    forget: 'Forget password?',
    noAccount: 'No account?',
    signUp: 'Get started for free',
    loginFailed: 'Login failed',
    welcome: 'Welcome to Malette.Art',
    signInOrSignUp: 'Sign in/up',
    sendVerifyCode: 'Send code',
    sendAgain: 'Send again',
    codeInvalid: 'Invalid code',
    emailInvalid: 'Invalid email',
    paramInvalid: 'Invalid parameter',
    footer: 'Login to agree <term>Terms of Service</term> and <privacy>Privacy Policy</privacy>, account will be created if you don\'t have one.',
  },
  OpenAPI: {
    APITokenDescription: 'API Token, get it from `User Center -> Developer` page',
  },
  signAgreementDialog: {
    title: 'Sign Agreement',
    content: 'Please read and agree to the <term>Terms of Service</term> and <privacy>Privacy Policy</privacy>, click agree to indicate that you have read and agreed, the agreement will take effect when you use the service.',
    cancel: 'Cancel',
    agree: 'Agree',
  },
  PaymentModal: {
    title: 'Recharge Prompt',
    content: 'M Coin is not enough, please recharge before using.',
    cancel: 'Cancel',
    agree: 'Go to recharge',
  },
  ComfyUI: 'ComfyUI',
  common: {
    more: 'Load More',
  },
  "ExploreWorkflows": "Explore AI Apps",
  "ExploreDescription": "Discover and use high-quality AI apps created by the community",
  "NoMoreWorkflows": "Already at the bottom ~",
  "NoWorkflowsYet": "No AI Apps Yet",
  "NoWorkflowsDescription": "There are no AI apps yet, create the first one now!"
};
