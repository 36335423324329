import { Select, Spin, Tabs } from "antd";


import styles from './index.module.scss';
import { useCallback, useEffect, useRef, useState } from "react";
import { listWorksPaged } from "@/services/Works";
import WorksMasonry from "./WorksMasonry";
import { useTranslation } from "react-i18next";
import { WorkflowDTO, listWorkflowCodes } from "@/services/Workflow";

const getTabItems = (works, t, workflowCodes, onWorkflowCodeChange, loaderElement, comfyuiLoaderElement) => [
  {
    key: 'AIWORKFLOW',
    label: `${t('AIApp')}`,
    children: <div className={styles.worksMasonry}>
      <div className={styles.workflowSelection}>
        <Select 
          showSearch
          allowClear
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          defaultValue={workflowCodes?.[0]?.code} 
          className={styles.workflowCodeSelect} 
          onChange={onWorkflowCodeChange!} 
          options={workflowCodes?.map(item => {
            return {
              label: item.name,
              value: item.code
            }
          })} 
        />
      </div>
      <WorksMasonry works={works} />
      {loaderElement}
    </div>
  },
  {
    key: 'COMFYUI_CANVAS',
    label: `${t('COMFYUI')}`,
    children: <div className={styles.worksMasonry}>
      <WorksMasonry works={works} />
      {comfyuiLoaderElement}
    </div>
  },
];

const Works: React.FC = () => {
  const [ currentType, setCurrentType ] = useState('AIWORKFLOW');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentWorkflowCode, setCurrentWorkflowCode] = useState<string>();
  const [workflowCodes, setWorkflowCodes] = useState<WorkflowDTO[]>();
  const loader = useRef(null);
  const comfyuiLoader = useRef(null);
  const loaderElement = <div ref={loader} className="flex justify-center item-center">{hasMoreData && <Spin />}</div>;
  const comfyuiLoaderElement = <div ref={comfyuiLoader} className="flex justify-center item-center">{hasMoreData && <Spin />}</div>;
  const [ t ] = useTranslation();

  useEffect(() => {
    const fetchWorkflowCodes = async () => {
      try {
        const response = await listWorkflowCodes();
        const data = response?.data;
        const codes = (data || []);
        setWorkflowCodes(codes);
      } catch (error) {
        console.error(error);
      }
    };

    fetchWorkflowCodes();
  }, []);

  // 加载数据的函数
  const loadMoreData = useCallback(async () => {
    try {
      setIsLoading(true);
      let response;
      if (currentType !== 'AIWORKFLOW') {
        response = await listWorksPaged(currentType, page, pageSize);
      } else {
        response = await listWorksPaged(currentWorkflowCode || 'AIWORKFLOW', page, pageSize);
      }
      const data = response.data
      const { list, hasMore, total } = data;
      setData(prevData => [...prevData, ...list]);
      setHasMoreData(hasMore);
      if (hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, [currentType, page, pageSize, currentWorkflowCode, workflowCodes]);

  
  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreData && !isLoading) {
      loadMoreData();
    }
  }, [loader, comfyuiLoader, isLoading, hasMoreData, loadMoreData]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
    if (comfyuiLoader.current) {
      observer.observe(comfyuiLoader.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver, loader?.current, comfyuiLoader?.current]);

  // 在组件首次加载时加载第一批数据
  useEffect(() => {
    loadMoreData();
  }, [currentType, currentWorkflowCode]);
  
  const onTabChange = (key) => {
    setCurrentWorkflowCode('');
    setCurrentType(key);
    setData([]);
    setPage(1);
    setHasMoreData(true);
  }

  const onWorkflowCodeChange = (code) => {
    setData([]);
    setPage(1);
    setCurrentWorkflowCode(code);
  };

  return (
    <div className={styles.works}>
      <Tabs 
        items={getTabItems(data, t, workflowCodes, onWorkflowCodeChange, loaderElement, comfyuiLoaderElement)}
        onChange={onTabChange}
      >
      </Tabs>
    </div>
  );
}

export default Works;