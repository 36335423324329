import { useEffect, useState } from "react";

export interface ILeftPanelProps {
  children: any;
  fixed?: boolean;
  onToggle?: (open: boolean) => void;
}

const LeftPanel = (props: ILeftPanelProps) => {
  const { children, fixed = false, onToggle } = props;
  const [ open, setOpen ] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const className = fixed || open ? 'w-72' : 'w-2';
  const left = fixed || open ? 'left-270px' : 'left-0';
  const top = fixed || open ? 'top-24' : 'top-4';

  return (
    <div className={`z-10 ${ fixed ? 'relative' : 'fixed' } left-0 h-full 
        overflow-y-scroll bg-white ${className} transition-width 
        duration-300 ease-in-out shadow-md border-r border-gray-200
        ${fixed ? 'hover: shadow-md' : 'hover:border-gray-300 hover:border-r-4'}
      `}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      <div
        style={{
          visibility: fixed ? 'hidden' : 'visible'
        }}
        className={`fixed ${top} ${left} transition-width 
          duration-300 ease-in-out flex justify-center items-center 
          w-8 h-8 bg-white rounded-full shadow-md cursor-pointer
          shadow-md
          ${isHovered || !open ? '' : 'invisible'}
        `}
        onClick={() => {
          setOpen(!open);
          onToggle?.call(null, !open);
        }}
      >
        {
          open ? <svg style={{ color: 'black' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
        </svg>
        : <svg style={{ color: 'black' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
      </svg>    
        }

      </div>
    </div>
  );
}

export default LeftPanel;