import React, { useEffect, useState, useCallback } from "react";
import { Progress, Spin } from "antd";
import moment from "moment";
import { Loading } from "@/components/Loading";
import { SdTaskResult } from "@/models/sd/SdFile";
import { convertHtmlToImage, onDownload } from "@/utils";
import HtmlOutputRender, { TextOutputRender } from "./components/HtmlOutputRender";
import styles from './index.module.scss';
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import TranscriptRender from "./components/TranscriptRender";

interface IWorksDetailRenderProps {
  result?: SdTaskResult;
  isMobilePortrait?: boolean;
  onClose?: () => void;
}

const WorksDetailRender = (props: IWorksDetailRenderProps) => {
  const { result, isMobilePortrait, onClose } = props;
  const [isDownloadHovering, setIsDownloadHovering] = useState(false);
  const [isCloseHovering, setIsCloseHovering] = useState(false);
  const loading = result?.loading;
  const imagePath = result?.results;
  const audioUrl = result?.audioUrl;
  const videoUrl = result?.videoUrl;
  const params = result?.params?.params;
  // console.log('result: ', result);
  const styleSuffix = (result?.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  const handleDownload = () => {
    console.log('result: ', result);
    if (result.results?.indexOf('```') !== -1 || result.contentType === 'HTML') {
      convertHtmlToImage('htmlRender');
    } else if (result.contentType === 'TEXT') {
      console.error('text output, no download');
    } else {
      if (!result?.publicId) {
        console.error('publicId is empty');
        return;
      }
      onDownload?.(
        result?.publicId, 
        `https://ablula.oss-accelerate.aliyuncs.com/${result?.results}`,
        result?.results?.endsWith?.('.webm') || result?.results?.endsWith?.('.mp4') ? 'video' : 'image'
      );
    }
  }
  let content;
  if (videoUrl) {
    content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://ablula.oss-accelerate.aliyuncs.com/${audioUrl}`} controls loop ></audio>;
  } else if (loading) {
    // content = <Loading visibility="visible" />;
    content = <div className="w-full flex flex-col items-center justify-center">
      <Loading visibility="visible" />
      { result.progress ? <Progress percent={Math.min(Math.max(result.progress, 1), 99)} /> : '准备中...' }
    </div>
  } else if (imagePath) {
    if (result.results?.indexOf('```') !== -1 || result.contentType === 'HTML') {
      try {
        content = <iframe height="100%" width="100%" src={`/static/llm-card/${result.publicId}`} />
      } catch (e) {
        console.error('parse origin results failed: ', e);
      }
    } else if (result.contentType === 'TEXT') {
      content = <TextOutputRender data={result.results} />
    } else if (result.contentType === 'TRANSCRIPT') {
      content = <TranscriptRender data={{
        summary: {
          title: '',
          sections: [],
        },
        transcripts: result.results ? JSON.parse(result.results) : [],
        todos: [],
      }} audioUrl={params?.audio_url10} />
    } else if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} controls loop />;
    } else {
      content = <img
        src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}${styleSuffix}`} 
        className="w-full h-auto object-contain max-h-[100%]"
      />;
    }
  }

  return (
    <div className="p-4 h-full w-full flex flex-col items-center justify-center relative">
      <div className="absolute top-0 right-0 z-10 flex gap-2">
        {/* Download button */}
        <button
          onClick={handleDownload}
          onMouseEnter={() => setIsDownloadHovering(true)}
          onMouseLeave={() => setIsDownloadHovering(false)}
          className="group"
          aria-label="Download"
        >
          <div className={`
            w-8 h-8 rounded-full flex items-center justify-center
            transition-all duration-300 ease-in-out
            ${isDownloadHovering ? 'bg-gray-200' : 'bg-gray-100/10 backdrop-blur-sm'}
          `}>
            <svg 
              className={`w-4 h-4 transition-all duration-300 ease-in-out ${isDownloadHovering ? 'text-gray-800' : 'text-gray-300'}`}
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4 4m0 0l-4-4m4 4V4" />
            </svg>
          </div>
        </button>

        {/* Close button */}
        {onClose && (
          <button
            onClick={onClose}
            onMouseEnter={() => setIsCloseHovering(true)}
            onMouseLeave={() => setIsCloseHovering(false)}
            className="group"
            aria-label="Close"
          >
            <div className={`
              w-8 h-8 rounded-full flex items-center justify-center
              transition-all duration-300 ease-in-out
              ${isCloseHovering ? 'bg-gray-200' : 'bg-gray-100/10 backdrop-blur-sm'}
            `}>
              <span className={`
                block w-4 h-0.5 rounded-full rotate-45 absolute
                transition-all duration-300 ease-in-out
                ${isCloseHovering ? 'bg-gray-800' : 'bg-gray-300'}
              `}></span>
              <span className={`
                block w-4 h-0.5 rounded-full -rotate-45 absolute
                transition-all duration-300 ease-in-out
                ${isCloseHovering ? 'bg-gray-800' : 'bg-gray-300'}
              `}></span>
            </div>
          </button>
        )}
      </div>

      <div className="h-full w-full flex flex-col items-center justify-center rounded-lg overflow-hidden shadow-lg">
        {content}
      </div>
    </div>
  );
}

const ResultPreview = (props) => {
  const { item, onSelect } = props;
  if (!item) {
    return null;
  }
  let content;
  const imagePath = item?.results;
  const audioUrl = item?.audioUrl;
  const videoUrl = item?.videoUrl;
  const styleSuffix = (item?.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  if (videoUrl) {
    content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://ablula.oss-accelerate.aliyuncs.com/${audioUrl}`} controls loop ></audio>;
  } else if (imagePath) {
    if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
      try {
        content = (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <iframe height="100%" width="100%" src={`/static/llm-card/${item.publicId}`} />
            <div 
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'transparent' }}
              onClick={() => onSelect?.(item)}
            />
          </div>
        );
      } catch (e) {
        console.error('parse origin results failed: ', e);
      }
    } else if (item.contentType === 'TEXT') {
      content = <TextOutputRender data={item.results} />;
    } else if (item.contentType === 'TRANSCRIPT') {
      content = "TRANSCRIPT";
    } else if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} controls loop />;
    } else {
      content = <img style={{
        objectFit: 'contain',
        height: '100%',
      }} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}${styleSuffix}`} />;
    }
  }
  return (
    <div className={styles.previewItem} onClick={() => { onSelect?.(item) }}>
      {content}
    </div>
  )
}

interface IWorksDetailProps {
  works?: SdTaskResult;
  worksList?: SdTaskResult[];
  workflow?: any;
  onClose?: () => void;
}

const WorksDetail = (props: IWorksDetailProps) => {
  const { works, worksList, workflow, onClose } = props;
  const [selectedWork, setSelectedWork] = useState(works);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    setSelectedWork(works);
  }, [works]);

  const HistoryItem = ({ work }) => (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => setSelectedWork(work)}
      className={`
        flex justify-center items-center
        cursor-pointer rounded-lg overflow-hidden transition-all duration-300
        ${work?.publicId === selectedWork?.publicId 
          ? 'ring-2 ring-blue-400 shadow-lg shadow-blue-500/50' 
          : 'hover:shadow-md'
        }
        ${isMobile ? 'w-20 h-20 flex-shrink-0' : 'w-full aspect-square mb-4'}
      `}
    >
      <ResultPreview item={work} />
    </motion.div>
  );

  return (
    <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} h-screen relative text-gray-100 bg-gray-900 overflow-hidden`}>
      {/* Subtle, high-quality background with glass effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900/90 via-gray-800/90 to-gray-900/90 backdrop-blur-md"></div>
      
      {/* Main content area */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`${isMobile ? 'h-[60vh]' : 'w-5/6 h-full'} p-4 relative z-10`}
      >
        <div className="w-full h-full flex items-center justify-center bg-gray-800/30 rounded-2xl overflow-hidden backdrop-filter backdrop-blur-sm">
          <WorksDetailRender result={selectedWork} isMobilePortrait={isMobile} onClose={onClose} />
        </div>
      </motion.div>

      {
        isMobile ? <motion.div 
          initial={{ opacity: 0, x: isMobile ? 0 : 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className={`${isMobile ? 'h-[15vh]' : 'w-1/6 h-full'} p-4 bg-gray-800/30 backdrop-blur-md ${isMobile ? 'border-t' : 'border-l'} border-gray-700/50 z-20 overflow-y-auto`}
        >
          <div className={`${isMobile ? 'flex space-x-4' : 'space-y-4'}`}>
            {worksList?.map((work) => (
              <HistoryItem key={work?.publicId} work={work} />
            ))}
          </div>
        </motion.div> : null
      }
      
      {/* <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`${isMobile ? 'h-[25vh]' : 'w-1/3 h-full'} p-4 bg-gray-800/50 backdrop-blur-md ${isMobile ? 'border-t' : 'border-l'} border-gray-700/50 z-20 overflow-y-auto`}
      >
        <h2 className="text-xl font-bold mb-2 text-gray-100">{selectedWork.title}</h2>
        <p className="flex justify-between items-center text-sm">
          <strong className="text-gray-300">生成时间：</strong> 
          <span className="text-gray-400 font-medium">
            {moment(selectedWork?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </p>

      </motion.div> */}

      {/* History area */}
      {
        !isMobile ? <motion.div 
          initial={{ opacity: 0, x: isMobile ? 0 : 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className={`${isMobile ? 'h-[15vh]' : 'w-1/6 h-full'} p-4 bg-gray-800/30 backdrop-blur-md ${isMobile ? 'border-t' : 'border-l'} border-gray-700/50 z-20 overflow-y-auto`}
        >
          <div className={`${isMobile ? 'flex space-x-4' : 'space-y-4'}`}>
            {worksList?.map((work) => (
              <HistoryItem key={work?.publicId} work={work} />
            ))}
          </div>
        </motion.div> : null
      }
    </div>
  );
};

export default WorksDetail;
