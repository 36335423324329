import { WorkflowDTO } from '@/services/Workflow';
import { useGlobalStore } from '@/store';
import React, { useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';
import "swagger-ui-react/swagger-ui.css"

export interface IApiDocumentationProps {
  workflow: WorkflowDTO;
  APITokenDescription: string;
}

function translatePrams2OpenAPISpec(params) {
  // 创建一个临时对象来构建 OpenAPI schema 结构
  const openApiSchema = {
    type: 'object',
    properties: {}
  };

  // 遍历输入参数
  for (const [key, value] of Object.entries(params)) {
    const propDef = {
      type: 'string', // 默认类型为 string，根据 paramType 进行调整
      description: value.name,
      default: value.defaultValue
    };

    switch (value.paramType) {
      case 'Number':
        propDef.type = 'number';
        if (value.component === 'NumberPicker' && value.props.step) {
          // OpenAPI 不直接支持 step 属性，但可添加至 description
          propDef.description += ` (允许步进值: ${value.props.step})`;
        }
        break;
      case 'Image':
        propDef.type = 'string';
        propDef.format = 'byte'; // 假设 base64 编码图片
        break;
      // 其他类型转换...
    }

    openApiSchema.properties[key] = propDef;
  }

  return openApiSchema;
}

// 现在 openApiRequestBody 可以插入到 OpenAPI 文档的 paths.{path}.post.requestBody 中

const ApiDocumentation = (props: IApiDocumentationProps) => {

  const { workflow, APITokenDescription } = props;
  const { code: workflowCode, paramTpl: params, description, name } = workflow;
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ spec, setSpec ] = React.useState({});

  useEffect(() => {
    if (!params) return;
    setSpec({
      openapi: "3.1.0",
      info: {
        title: `Workflow API - ${name}`,
        description: `${description} - ${workflowCode}`,
      },
      servers: null,
      paths: {
        [`/open/api/v1/workflow/${workflowCode}`]: {
          post: {
            tags: [''],
            summary: 'Run workflow - ' + workflowCode,
            parameters: [
              {
                "name": "x-malette-authorization",
                "in": "header",
                "description": APITokenDescription,
                "schema": {
                  "type": "string"
                },
                "example": currentUser?.apiToken
              }
            ],
            requestBody: {
              required: true,
              content: {
                'application/json': {
                  schema: translatePrams2OpenAPISpec(params)
                }
              }
            },
            responses: {
              '200': {
                description: 'OK',
                content: {
                  'application/json': {
                    schema: {
                      type: 'object',
                      properties: {
                        "code": {
                          type: "number",
                          description: "status code"
                        },
                        "success": {
                          type: "boolean",
                          description: "is success"
                        },
                        "data": {
                          type: "object",
                          properties: {
                            "publicId": {
                              type: "string",
                              description: "task id"
                            },
                            "status": {
                              type: "string",
                              description: "task status"
                            },
                            "stage": {
                              type: "string",
                              description: "task stage"
                            },
                            "createdAt": {
                              type: "string",
                              description: "createdAt"
                            },
                            "updatedAt": {
                              type: "string",
                              description: "updatedAt"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
          }
        },
        [`/open/api/v1/workflow/${workflowCode}/results`]: {
          post: {
            tags: [''],
            summary: 'get workflow results',
            parameters: [
              {
                "name": "x-malette-authorization",
                "in": "header",
                "description": APITokenDescription,
                "schema": {
                  "type": "string"
                },
                "example": currentUser?.apiToken
              }
            ],
            requestBody: {
              required: true,
              content: {
                'application/json': {
                  schema: {
                    type: 'object',
                    properties: {
                      taskId: {
                        type: 'string',
                        description: 'task id'
                      },
                    }
                  }
                }
              },
            },
            responses: {
              '200': {
                description: 'OK',
                content: {
                  'application/json': {
                    schema: {
                      type: 'object',
                      properties: {
                        "code": {
                          type: "number",
                          description: "status code"
                        },
                        "success": {
                          type: "boolean",
                          description: "is success"
                        },
                        "data": {
                          type: "object",
                          properties: {
                            "taskId": {
                              type: "string",
                              description: "task id"
                            },
                            "status": {
                              type: "string",
                              description: "task status"
                            },
                            "stage": {
                              type: "string",
                              description: "task stage"
                            },
                            "results": {
                              type: "array",
                              items: {
                                type: "object",
                                properties: {
                                  "publicId": {
                                    type: "string",
                                    description: "result id"
                                  },
                                  "taskId": {
                                    type: "string",
                                    description: "task id"
                                  },
                                  "result": {
                                    type: "object",
                                    properties: {
                                      "url": {
                                        type: "string",
                                        description: "result url"
                                      }
                                    }
                                  }
                                }
                              }
                            },
                            "params": {
                              type: "object",
                              properties: {
                                "workflowCode": {
                                  type: "string",
                                  description: "workflow code"
                                },
                                "params": {
                                  type: "object",
                                  properties: {
                                    "positive61": {
                                      type: "string",
                                      description: "positive61"
                                    },
                                    "batch_size61": {
                                      type: "number",
                                      description: "batch_size61"
                                    }
                                  }
                                }
                              }
                            },
                            "createdAt": {
                              type: "string",
                              description: "createdAt"
                            },
                            "updatedAt": {
                              type: "string",
                              description: "updatedAt"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
          }
        }
      }
    });
  }, [params, APITokenDescription]);

  if (!params || !spec) return (<div>loading...</div>);

  return (
    <SwaggerUI
      spec={spec}
    />
  );
}

export default ApiDocumentation;