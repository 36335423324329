import { WorkflowItem, DEFAULT_COVER } from "@/constants";
import { Button, Empty, Upload } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "@/store";
import { IWorkflowItemProps } from "@/pages/explore";
import { motion } from "framer-motion";

const WorkflowItemComp: React.FC<IWorkflowItemProps> = (props) => {
  const { item, onEdit, onlyPreview } = props;
  const { name, description, cover, ownerId, publicId, code } = item;
  const fileExt = (cover || DEFAULT_COVER)?.split('!')[0]?.split('?')[0].split('.').pop() || 'png';
  const [hover, setHover] = useState(false);
  const [t] = useTranslation();

  const handleCardClick = (e: React.MouseEvent) => {
    // 防止按钮点击事件冒泡
    if ((e.target as HTMLElement).closest('button')) {
      return;
    }
    window.open(`/workflow/${code}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      transition={{ 
        duration: 0.2,
        translateY: {
          type: "spring",
          stiffness: 300,
          damping: 30
        }
      }}
      onClick={handleCardClick}
      className="group relative bg-gradient-to-b from-gray-800/50 to-gray-900/50 rounded-xl overflow-hidden backdrop-blur-sm border border-gray-800/50 cursor-pointer transform-gpu"
    >
      {/* 封面区域 */}
      <div 
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="relative aspect-[16/9] overflow-hidden"
      >
        {['webm', 'mp4'].includes(fileExt) ? (
          <video 
            src={cover} 
            controls 
            loop 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="relative w-full h-full overflow-hidden">
            <img 
              src={cover || DEFAULT_COVER} 
              alt={name}
              className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105 will-change-transform"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </div>
        )}
        
        {/* 操作按钮遮罩 */}
        <div 
          className={`absolute inset-0 bg-black/60 flex items-center justify-center gap-4 transition-all duration-300 ${
            hover ? 'opacity-100 backdrop-blur-sm' : 'opacity-0 backdrop-blur-none'
          }`}
        >
          {!onlyPreview && (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                onEdit?.(item);
              }}
              className="bg-white/10 border-white/20 hover:bg-white/20 backdrop-blur-sm transform-gpu transition-transform hover:scale-105"
            >
              {t('editApp')}
            </Button>
          )}
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/workflow/${code}`);
            }}
            className="bg-indigo-500 hover:bg-indigo-600 border-0 transform-gpu transition-transform hover:scale-105"
          >
            {t('openApp')}
          </Button>
        </div>
      </div>

      {/* 底部信息区 */}
      <div className="p-4">
        <div className="flex items-start gap-3">
          <div className="flex-shrink-0">
            <img 
              src={`/profile/avatar/${ownerId}`}
              className="w-10 h-10 rounded-full ring-2 ring-gray-700/50"
              alt="avatar"
              loading="lazy"
            />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-medium text-white truncate group-hover:text-indigo-300 transition-colors">
              {name}
            </h3>
            <p className="mt-1 text-sm text-gray-400 line-clamp-2 group-hover:text-gray-300 transition-colors">
              {description || '暂无描述'}
            </p>
          </div>
        </div>
      </div>

      {/* 渐变边框效果 */}
      <div className="absolute inset-0 border border-transparent rounded-xl bg-gradient-to-r from-indigo-500/0 via-indigo-500/10 to-purple-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    </motion.div>
  );
};

const WorkflowList: React.FC<{
  onlyPreview?: boolean,
  list: WorkflowItem[],
  onEdit?: (item: WorkflowItem) => void,
  handleWorkflowUpload?: (file: File) => Promise<boolean>,
}> = (props) => {
  const { list, onEdit, onlyPreview, handleWorkflowUpload } = props;
  const [t] = useTranslation();
  const currentUser = useGlobalStore((state) => state.currentUser);

  return list.length ? (
    <div 
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
      style={{ 
        willChange: 'transform',
        transform: 'translateZ(0)',
      }}
    >
      {list.map((workflow) => (
        <WorkflowItemComp
          key={workflow.publicId}
          item={workflow}
          onEdit={onEdit}
          onlyPreview={onlyPreview}
        />
      ))}
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center py-20">
      <img
        src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        className="w-20 h-20 mb-6 opacity-50"
        alt="empty"
      />
      <p className="text-gray-400 mb-6">{t('NoWorkflow')}</p>
      <Upload
        disabled={!currentUser?.publicId}
        accept=".json,.jpeg,.png,.webp"
        itemRender={() => null}
        beforeUpload={handleWorkflowUpload}
      >
        <Button 
          disabled={!currentUser?.publicId} 
          type="dashed"
          className="border-gray-600 text-gray-400 hover:border-indigo-500 hover:text-indigo-400"
        >
          立即上传
        </Button>
      </Upload>
    </div>
  );
};

export default WorkflowList;