
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Spin, message } from 'antd';

import { useGlobalStore } from '@/store';
import { updateProfile } from '@/services/user';
import AvatarUploader from '@/components/AvatarUploader';

import styles from './index.module.scss';
import { FieldData } from '@/constants';

export default function Profile() {
  const user = useGlobalStore((state) => state.currentUser);
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);
  const [loading, setLoading] = useState(false);
  const [ formFields, setFormFields ] = useState<FieldData[]>([]);
  const [needUpdates, setNeedUpdates] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!user) return;

    const fields: FieldData[] = [
      {
        name: 'name',
        label: 'Username',
        type: 'input',
        required: true,
        disabled: true,
        value: user.name,
      },
      {
        name: 'displayName',
        label: 'displayName',
        type: 'input',
        required: true,
        disabled: true,
        value: user.displayName,
      },
      {
        name: 'email',
        label: 'Public email',
        type: 'input',
        required: true,
        disabled: true,
        value: user.email,
      },
      {
        name: 'introduction',
        label: 'Bio',
        type: 'textarea',
        required: true,
        disabled: true,
        value: user.introduction,
      },
      {
        name: 'avatar',
        label: 'Avatar',
        type: 'avatar',
        required: true,
        disabled: true,
        value: user.avatar,
      },
    ];
    setFormFields(fields);

  }, [user]);

  const onUserDataChange = useCallback((changedFields, allFields) => {
    let _needUpdates = false;
    for (const field of allFields) {
      const newValue = user?.[field?.name?.[0]];
      const oldValue = field.value;
      if (!newValue && !oldValue) {
        continue;
      }
      if (oldValue !== newValue) {
        _needUpdates = true;
        break;
      }
    }
    setNeedUpdates(_needUpdates);
  }, []);

  const save = useCallback(async () => {
    setLoading(true);
    const profile = form.getFieldsValue();
    const res = await updateProfile(profile);
    if (res.success) {
      message.success('Profile updated');
    } else {
      message.error('Profile update failed');
    }
    getUserInfo();
    setLoading(false);
  }, []);

  return (
    <div className={styles.accountSettings}>
      <Spin spinning={loading} ><div className={styles.profile}>
        <span className={styles.title}>Profile</span>
        <Form form={form} fields={formFields} layout="vertical" onFieldsChange={onUserDataChange} >
          <div className={styles.content}>
            <div className={styles.group}>
              <div className={styles.item}>
                <Form.Item label="Username" name="name" rules={[{ required: true }]}>
                  <Input className={styles.input} />
                </Form.Item>
              </div>
              <div className={styles.item}>
                <Form.Item label="DisplayName" name="displayName">
                  <Input className={styles.input} />
                </Form.Item>
              </div>
            </div>
            <div className={styles.item}>
              <Form.Item label="Public email" name="email">
                <Input className={styles.input} />
              </Form.Item>
            </div>
            <div className={styles.item}>
              <Form.Item label="Bio" name="introduction">
                <Input.TextArea
                  className={styles.input}
                />
              </Form.Item>
            </div>
            <div className={styles.avatarConfig}>
              <Form.Item label="Avatar" name='avatar'>
                <AvatarUploader
                  enableCrop
                />
              </Form.Item>
            </div>
            <div className={styles.submit}>
              <Button disabled={!needUpdates} className={styles.submitButton} onClick={save} type="primary">Save</Button>
            </div>
          </div>
        </Form>
      </div></Spin>
    </div>
  );
}
