import { useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-python';

interface TerminalProps {
  code: string;
  language?: string;
}

export function Terminal({ code, language = 'javascript' }: TerminalProps) {
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [code]);

  return (
    <div className="relative rounded-xl overflow-hidden bg-[#1E1E1E] shadow-2xl" style={{ maxWidth: 'calc(100vw - 2rem)' }}>
      {/* Terminal Header */}
      <div className="flex items-center px-4 py-3 bg-[#2D2D2D] border-b border-gray-800">
        <div className="flex space-x-2">
          <div className="w-3 h-3 rounded-full bg-[#FF5F56]" />
          <div className="w-3 h-3 rounded-full bg-[#FFBD2E]" />
          <div className="w-3 h-3 rounded-full bg-[#27C93F]" />
        </div>
        <div className="absolute left-1/2 -translate-x-1/2 text-sm text-gray-400">
          Example Code
        </div>
      </div>
      
      {/* Terminal Content */}
      <div className="p-4 overflow-x-auto">
        <pre className="text-sm" style={{ height: 248 }}>
          <code 
            ref={codeRef} 
            className={`language-${language} block w-full overflow-x-scroll`}
          >
            {code}
          </code>
        </pre>
      </div>

      {/* Terminal Decorations */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-purple-500/10 rounded-full blur-2xl" />
      <div className="absolute bottom-0 left-0 w-32 h-32 bg-pink-500/10 rounded-full blur-2xl" />
    </div>
  );
}