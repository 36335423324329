import { useState, useRef, useEffect } from 'react';
import { PlayCircle, PauseCircle } from 'lucide-react';

interface Word {
  start: number;
  end: number;
  word: string;
  probability: number;
}

interface Transcript {
  start: number;
  end: number;
  speaker: string;
  text: string;
  words: Word[];
  avg_logprob: number;
}

interface TranscriptRenderProps {
  data: {
    transcripts: Transcript[];
    summary: {
      title: string;
      sections: {
        title: string;
        items: string[];
      }[];
    };
    todos: string[];
  };
  audioUrl: string;
}

const TranscriptRender = ({ data, audioUrl }: TranscriptRenderProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const transcriptRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(Math.floor(audioRef.current.currentTime));
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(Math.floor(audioRef.current.duration));
    }
  };

  const handleSeek = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  // 修改判断逻辑，确保每个词都能被高亮
  const isWordActive = (start: number, end: number) => {
    // 如果没有时间戳信息，使用所在段落的时间
    if (!start || !end) return false;
    return currentTime >= start && currentTime <= end;
  };

  // 判断当前段落是否应该高亮
  const isTranscriptActive = (start: number, end: number) => {
    return currentTime >= start && currentTime <= end;
  };

  // 修改：处理文本分词和高亮，但保持原始文本完整性
  const processText = (text: string, words: Word[]) => {
    if (!words || words.length === 0) {
      return <span className="text-gray-300">{text}</span>;
    }

    let lastIndex = 0;
    const result = [];
    
    // 处理每个带时间戳的单词
    words.forEach((word, idx) => {
      // 添加当前单词之前的文本（如果有）
      const wordStart = text.indexOf(word.word, lastIndex);
      if (wordStart > lastIndex) {
        result.push(
          <span key={`text-${idx}`} className="text-gray-300">
            {text.slice(lastIndex, wordStart)}
          </span>
        );
      }

      // 添加带时间戳的单词
      result.push(
        <span
          key={`word-${idx}`}
          className={`
            relative inline-block transition-all duration-300 ease-in-out
            ${isWordActive(word.start, word.end) ? 'text-blue-200' : ''}
          `}
        >
          <span 
            className={`
              absolute inset-0 rounded-md -z-10
              transition-all duration-500 ease-in-out
              ${isWordActive(word.start, word.end)
                ? 'bg-blue-500/20 scale-110'
                : 'bg-transparent scale-90 opacity-0'
              }
            `}
          />
          <span className="relative px-0.5">
            {word.word}
          </span>
        </span>
      );

      lastIndex = wordStart + word.word.length;
    });

    // 添加最后剩余的文本（如果有）
    if (lastIndex < text.length) {
      result.push(
        <span key="text-end" className="text-gray-300">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return result;
  };

  // 自动滚动到当前播放位置
  useEffect(() => {
    const activeElement = document.querySelector('[data-active="true"]');
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentTime]);

  return (
    <div className="h-full flex flex-col bg-gray-900">
      <div className="sticky top-0 bg-gray-800/80 border-b border-gray-700/50 backdrop-blur-md z-10">
        <div className="max-w-4xl mx-auto px-6 py-4">
          <audio
            ref={audioRef}
            src={audioUrl}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
          />
          <div className="flex items-center gap-4">
            <button 
              onClick={() => setIsPlaying(!isPlaying)}
              className="text-blue-400 hover:text-blue-300 transition-colors"
            >
              {isPlaying ? <PauseCircle size={32} /> : <PlayCircle size={32} />}
            </button>
            <div className="flex-1">
              <input 
                type="range" 
                min="0" 
                max={duration} 
                value={currentTime} 
                onChange={(e) => handleSeek(Number(e.target.value))}
                className="w-full accent-blue-500 h-2 rounded-lg bg-gray-700"
              />
            </div>
            <span className="font-mono text-sm text-gray-400 w-24 text-right">
              {Math.floor(currentTime / 60)}:{(currentTime % 60).toString().padStart(2, '0')} / 
              {Math.floor(duration / 60)}:{(duration % 60).toString().padStart(2, '0')}
            </span>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="max-w-4xl mx-auto space-y-8 p-6">
          <div className="bg-gray-800/50 rounded-xl border border-gray-700/50 backdrop-blur-sm overflow-hidden">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700/50">
              <h2 className="text-xl font-semibold text-gray-100">Summary</h2>
              <button className="px-4 py-2 text-sm font-medium text-blue-400 hover:text-blue-300 transition-colors">
                Copy Summary
              </button>
            </div>
            <div className="p-6 space-y-6">
              {data.summary?.sections?.map?.((section, idx) => (
                <div key={idx}>
                  <h3 className="text-lg font-medium text-gray-200 mb-3">{section.title}</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    {section?.items?.map?.((item, itemIdx) => (
                      <li key={itemIdx} className="text-gray-300 leading-relaxed">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-800/50 rounded-xl border border-gray-700/50 backdrop-blur-sm overflow-hidden">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700/50">
              <h2 className="text-xl font-semibold text-gray-100">Action Items</h2>
              <button className="px-4 py-2 text-sm font-medium text-blue-400 hover:bg-gray-700/50 rounded-md transition-colors">
                Add all to Inbox
              </button>
            </div>
            <div className="p-4">
              {data.todos?.map?.((todo, idx) => (
                <label 
                  key={idx} 
                  className="flex items-start gap-3 px-4 py-3 hover:bg-gray-700/30 rounded-lg transition-colors cursor-pointer"
                >
                  <input 
                    type="checkbox" 
                    className="mt-1 h-4 w-4 rounded border-gray-600 bg-gray-700 text-blue-500 focus:ring-blue-500 focus:ring-offset-gray-900"
                  />
                  <span className="text-gray-300 leading-relaxed">{todo}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="bg-gray-800/50 rounded-xl border border-gray-700/50 backdrop-blur-sm overflow-hidden">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700/50">
              <h2 className="text-xl font-semibold text-gray-100">Transcript</h2>
              <button className="px-4 py-2 text-sm font-medium text-blue-400 hover:text-blue-300 transition-colors">
                Copy Transcript
              </button>
            </div>
            <div className="p-4" ref={transcriptRef}>
              {data.transcripts?.map?.((item, idx) => (
                <div 
                  key={idx} 
                  data-active={isTranscriptActive(item.start, item.end)}
                  className={`flex gap-4 px-4 py-3 rounded-lg transition-all duration-500 ease-in-out ${
                    isTranscriptActive(item.start, item.end) 
                      ? 'bg-gray-700/40 shadow-lg' 
                      : 'hover:bg-gray-700/20'
                  }`}
                >
                  <span className="text-gray-500 font-mono w-16 flex-shrink-0">
                    {Math.floor(item.start / 60)}:{Math.floor(item.start % 60).toString().padStart(2, '0')}
                  </span>
                  <div className="flex-1">
                    <span className="font-medium text-blue-400">{item.speaker}</span>
                    <span className="ml-2 text-gray-300 leading-relaxed">
                      {item.words?.length > 0 
                        ? processText(item.text, item.words)
                        : item.text // 如果没有 words 数据，直接显示完整文本
                      }
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscriptRender;