import { Button, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { WorkflowItem } from "@/constants";
import { listWorkflowsByPage } from '@/services/Workflow';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/store';
import WorkflowList from '../workflow/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline'; // 需要安装 @heroicons/react
import { motion } from 'framer-motion';

export interface IWorkflowItemProps {
  item: WorkflowItem;
  onEdit?: (item?: WorkflowItem) => void;
  onlyPreview?: boolean;
}

const WorkflowHome: React.FC = () => {
  const [ t ] = useTranslation();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ defaultWorkflowList, setDefaultWorkflowList ] = useState<WorkflowItem[]>([]);
  const [ loading, setLoading ] = useState(false);
  const [ pageNo, setPageNo ] = useState(1);
  const [ hasMore, setHasMore ] = useState(true);

  useEffect(() => {
    fetchDefaultWorkflowList();
  }, [pageNo]);

  const fetchDefaultWorkflowList = async () => {
    try {
      setLoading(true);
      const response = await listWorkflowsByPage({ isDefault: true }, pageNo, 20);
      const workflowList = response.data?.list?.map?.(item => {
        return {
          ...item,
          contentTpl: JSON.parse(item?.contentTpl || '{}'),
          paramTpl: JSON.parse(item?.paramTpl || '{}'),
          outputTpl: JSON.parse(item?.outputTpl || '{}'),
        };
      });
      setDefaultWorkflowList(prev => pageNo === 1 ? workflowList : [...prev, ...workflowList]);
      setHasMore(workflowList?.length === 20);
    } catch (error) {
      notification.error({ message: '获取默认工作流列表失败', description: error.message });
    } finally {
      setLoading(false);
    }
  };

  const throttle = (func: Function, limit: number) => {
    let inThrottle: boolean;
    return function(...args: any[]) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    }
  };

  useEffect(() => {
    const loadMoreRef = document.querySelector('#loadMoreTrigger');
    if (!loadMoreRef) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMore) {
          setPageNo(prev => prev + 1);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    observer.observe(loadMoreRef);
    return () => observer.disconnect();
  }, [loading, hasMore]);

  const handleMakeAIApp = () => {
    window.open('/workflow/app/new', '_blank');
  };

  return (
    <div className="relative min-h-screen bg-slate-950" style={{ marginTop: -64 }}>
      {/* 背景层 - 使用 CSS 自定义属性实现更好的性能 */}
      <div 
        className="fixed inset-0 pointer-events-none"
        style={{
          '--grid-color': 'rgba(79, 79, 79, 0.18)',
          '--mask-position': '50% 0%',
        } as React.CSSProperties}
      >
        {/* 网格背景 */}
        <div 
          className="absolute inset-0 opacity-50"
          style={{
            backgroundImage: `
              linear-gradient(to right, var(--grid-color) 1px, transparent 1px),
              linear-gradient(to bottom, var(--grid-color) 1px, transparent 1px)
            `,
            backgroundSize: '14px 24px',
            maskImage: 'radial-gradient(ellipse 80% 50% at var(--mask-position), black 70%, transparent 110%)',
            WebkitMaskImage: 'radial-gradient(ellipse 80% 50% at var(--mask-position), black 70%, transparent 110%)',
          }}
        />
        
        {/* 渐变背景 */}
        <div 
          className="absolute inset-0 bg-gradient-to-b from-indigo-500/20 via-transparent to-transparent opacity-30"
          style={{
            transform: 'skewY(-12deg) translateY(-10%)',
            willChange: 'transform',
          }}
        />
      </div>

      {/* 内容容器 - 使用 Composition Layer 优化性能 */}
      <div className="relative z-10 will-change-transform">
        {/* Hero Section */}
        <div className="relative isolate">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-20">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center space-y-8"
            >
              <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300">
                {t('ExploreWorkflows')}
              </h1>

              <p className="text-xl text-gray-400 max-w-2xl mx-auto leading-relaxed">
                {t('ExploreDescription')}
              </p>

              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="inline-block"
              >
                <button
                  onClick={() => handleMakeAIApp()}
                  disabled={!currentUser?.publicId}
                  className="group relative inline-flex items-center justify-center gap-2 h-14 px-10 
                    bg-gradient-to-r from-indigo-600 to-violet-600 
                    disabled:from-gray-600 disabled:to-gray-700 disabled:cursor-not-allowed
                    text-white font-medium rounded-xl
                    transition-all duration-300
                    hover:shadow-[0_0_30px_-5px] hover:shadow-indigo-500/50
                    focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                >
                  <PlusCircleIcon className="w-6 h-6 transition-transform group-hover:rotate-90" />
                  <span>{t('UploadWorkflow')}</span>
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-xl blur opacity-30 group-hover:opacity-50 transition duration-300" />
                </button>
              </motion.div>
            </motion.div>
          </div>
        </div>

        {/* Content Section - 添加合成层优化 */}
        <div 
          className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
          style={{ willChange: 'transform' }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 shadow-2xl"
            style={{ 
              backfaceVisibility: 'hidden',
              transform: 'translateZ(0)',
            }}
          >
            <WorkflowList onlyPreview list={defaultWorkflowList} />
          </motion.div>
          
          {/* Loading Indicator */}
          {loading && (
            <div className="flex justify-center items-center py-12">
              <div className="relative">
                <div className="w-12 h-12 rounded-full border-t-4 border-b-4 border-indigo-500 animate-spin" />
                <div className="absolute inset-0 w-12 h-12 rounded-full border-t-4 border-b-4 border-violet-500 animate-spin blur-sm opacity-50" />
              </div>
            </div>
          )}
          
          {/* Load More Trigger */}
          {hasMore && (
            <div id="loadMoreTrigger" className="h-20 w-full" />
          )}
          
          {/* End Message */}
          {!hasMore && defaultWorkflowList.length > 0 && (
            <div className="text-center py-12">
              <p className="text-gray-400 font-medium">
                {t('NoMoreWorkflows')}
              </p>
            </div>
          )}
          
          {/* Empty State */}
          {!loading && defaultWorkflowList.length === 0 && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center py-20 backdrop-blur-sm bg-white/5 rounded-2xl"
            >
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-800/50 mb-6">
                <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-200 mb-3">
                {t('NoWorkflowsYet')}
              </h3>
              <p className="text-gray-400 max-w-md mx-auto">
                {t('NoWorkflowsDescription')}
              </p>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowHome;