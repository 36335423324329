import { Image, Space } from "antd";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import styles from './index.module.scss';
import { getFilenameFromUrl, onDownload } from "@/utils";
import { DownloadOutlined, SwapOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";

const MyImage = (props) => {
  const { src } = props;
  return <Image 
    preview={{
      toolbarRender: (
        _,
        {
          transform: { scale },
          actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
        },
      ) => (
        <Space size={12} className="toolbar-wrapper">
          <DownloadOutlined onClick={() => {
            onDownload(getFilenameFromUrl(src), src);
          }} />
          <SwapOutlined rotate={90} onClick={onFlipY} />
          <SwapOutlined onClick={onFlipX} />
          <RotateLeftOutlined onClick={onRotateLeft} />
          <RotateRightOutlined onClick={onRotateRight} />
          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
        </Space>
      ),
    }}
    {...props} 
  />
}

const WorksMasonry = (props) => {

  const { works } = props;


  return <ResponsiveMasonry
    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
  >
    <Masonry
      gutter="20px"
    >
        {
          works.map((work, index) => {
            return <div key={index} className={styles.workItem}>
              { 
                work.results.endsWith('.webm') || work.results.endsWith('.mp4')
                  ? <video style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${work.results}`} controls loop /> 
                  : <MyImage style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${work.results}${work.results?.endsWith('webp') ? '' : '!s'}`} /> 
              }
            </div>
          })
        }
    </Masonry>
  </ResponsiveMasonry>
}

export default WorksMasonry;
